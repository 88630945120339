import {node, string} from 'prop-types'
import React from 'react'

import useStyles from './styles'

const Vignette = ({borderRadius, children}) => {
  const classes = useStyles()

  return (
    <div className={classes.vignetteWrapper} style={{borderRadius}}>
      <div style={{borderRadius}} className={classes.vignetteSubWrapper}>
        <div className={classes.vignetteDiv}>{children}</div>
      </div>
    </div>
  )
}

Vignette.propTypes = {
  borderRadius: string,
  children: node.isRequired,
}

Vignette.defaultProps = {
  borderRadius: 20,
}

export default Vignette
