import {Link} from 'gatsby'
import {node, string} from 'prop-types'
import React from 'react'

import useStyles from './styles'

const ArticleVignette = ({
  data,
  image,
  borderRadius,
  children,
  margin,
  justifyContent,
}) => {
  const classes = useStyles()

  const {slug} = data

  return (
    <div className={classes.vignetteWrapper}>
      <Link to={`../news/${slug}`} className={classes.vignetteLink}>
        <div style={{backgroundImage: image}} className={classes.vignetteImage}>
          <div
            style={{
              borderRadius,
              background:
                'linear-gradient(180deg, rgb(255 255 255) 0%, rgb(225 225 225 / 0%) 0.01%, rgb(241 241 241 / 14%) 51.56%, rgb(255 255 255) 100%)',
            }}
            className={classes.vignetteSubWrapper}
          >
            <div
              style={{margin, justifyContent}}
              className={classes.vignetteDiv}
            >
              {children}
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}

ArticleVignette.propTypes = {
  borderRadius: string,
  children: node.isRequired,
}

ArticleVignette.defaultProps = {
  borderRadius: 20,
}

export default ArticleVignette
