/* eslint-disable no-underscore-dangle */
import * as R from 'ramda'
import {objectOf} from 'prop-types'
import {stringToSlug} from 'helpers/utils'
import React, {useEffect, useState} from 'react'
import useIsMobile from 'hooks/useIsMobile'

import DesktopCarousel from './DesktopSlider'
import MobileCarousel from './MobileSlider'

const ContentSlider1 = ({slider}) => {
  const isMobile = useIsMobile()
  const [index, setIndex] = useState(0)

  const handleContentTypeTag = () => {
    const currentSlider = slider[index]

    if (currentSlider?.__typename === 'ContentfulInitiative') {
      return `#${currentSlider.__typename.replace('Contentful', '')}`
    }

    return `#${currentSlider?.type?.name?.name}`
  }

  const renderSlug = item => {
    if (item.type) {
      if (
        item.type.name.name === 'Project' ||
        item.type.name.name === 'Projet'
      ) {
        return `../committed-employees#${stringToSlug(
          item.primaryText.primaryText,
        )}`
      }
    }
    if (item.__typename) {
      if (item.__typename === 'ContentfulProgram') {
        return `../solidarity-programs/${item.slug}`
      }
    }

    return `../news/${item.slug}`
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (index === R.length(slider) - 1) {
        setIndex(0)
      } else {
        setIndex(prevIndex => prevIndex + 1)
      }
    }, 7000)

    return () => clearTimeout(timeoutId)
  }, [index])

  return isMobile ? (
    <MobileCarousel
      index={index}
      setIndex={setIndex}
      slider={slider}
      renderSlug={renderSlug}
    />
  ) : (
    <DesktopCarousel
      handleContentTypeTag={handleContentTypeTag}
      index={index}
      setIndex={setIndex}
      slider={slider}
      renderSlug={renderSlug}
    />
  )
}

ContentSlider1.propTypes = {
  slider: objectOf,
}

ContentSlider1.defaultProps = {
  slider: {},
}

export default ContentSlider1
