/* eslint-disable no-underscore-dangle */
import * as R from 'ramda'
import {Link} from 'gatsby'
import Button from '@mui/material/Button'
import classNames from 'classnames'
import Fade from '@mui/material/Fade'
import React, {useCallback} from 'react'

import LeftArrow from 'components/UI/LeftArrow'
import Media from 'components/UI/Media'
import RightArrow from 'components/UI/RightArrow'
import Title from 'components/UI/Title'
import useIsMobile from 'hooks/useIsMobile'
import useIsSmallMobile from 'hooks/useIsSmallMobile'

import LinearProgress from '../../LinearProgress'
import useStyles from './styles'

const DesktopCarousel = ({
  index,
  setIndex,
  slider,
  renderSlug,
  handleContentTypeTag,
}) => {
  const classes = useStyles()
  const isMobile = useIsMobile()
  const isSmallMobile = useIsSmallMobile()

  const nextSlide = useCallback(() => {
    setIndex(prevIndex =>
      prevIndex === R.length(slider) - 1 ? 0 : prevIndex + 1,
    )
  }, [slider, setIndex])

  const prevSlide = useCallback(() => {
    setIndex(prevIndex =>
      prevIndex === 0 ? R.length(slider) - 1 : prevIndex - 1,
    )
  }, [slider, setIndex])

  return (
    <>
      {slider.map((item, i) => (
        <>
          <Fade in>
            <div
              key={slider[index]?.contentful_id}
              className={`${classes.carouselItemContainer} carousel-item ${
                i === index ? 'active' : ''
              }`}
            >
              <Media
                className={classes.carouselImage}
                data={slider[index]?.image?.gatsbyImageData}
              />
              <LinearProgress />
            </div>
          </Fade>

          <Link
            to={renderSlug(item)}
            className={` ${i === index ? classes.linkContainer : ''}`}
            style={{
              zIndex: (isMobile || isSmallMobile) && 2001,
            }}
          />

          <div
            className={classNames(classes.carouselArrowsWrapper, {
              [`carousel-item ${i === index ? 'active' : ''}`]:
                isMobile || isSmallMobile,
            })}
            style={{
              zIndex: (isMobile || isSmallMobile) && 1000,
            }}
          >
            <div
              className={classNames(classes.carouselArrowsContainer, {
                [`carousel-item ${i === index ? 'active' : ''}`]:
                  isMobile || isSmallMobile,
              })}
              style={{
                position: (isMobile || isSmallMobile) && 'absolute',
                zIndex: (isMobile || isSmallMobile) && 1000,
              }}
            >
              <div>
                <Button
                  className={classNames(classes.tag, classes.serviceTags)}
                  size="small"
                >
                  {handleContentTypeTag(slider[index])}
                </Button>
              </div>
              <Title hasSmallPaddingBottom variant="h2" type="smallTitle">
                {slider[index]?.__typename === 'ContentfulContent'
                  ? slider[index]?.primaryText?.primaryText
                  : slider[index]?.title}
              </Title>
              <div className={classes.carouselArrowIconsContainer}>
                <button type="button" onClick={prevSlide}>
                  <LeftArrow />
                </button>

                <button type="button" onClick={nextSlide}>
                  <RightArrow />
                </button>
              </div>
            </div>
          </div>
        </>
      ))}
    </>
  )
}

export default DesktopCarousel
