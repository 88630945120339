import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({breakpoints, spacing}) => ({
  vignetteWrapper: {
    flex: 1,
    width: '100%',
  },
  vignetteSubWrapper: {
    height: '100%',
    width: '100%',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    display: 'flex',
  },
  vignetteDiv: {
    display: 'flex',
    padding: spacing(1.5),
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: 'auto',
    fontFamily: 'CamptonBook',
    [breakpoints.down('sm')]: {
      padding: spacing(3, 1),
      height: 200,
    },
  },
}))

export default useStyles
