import * as R from 'ramda'
import {arrayOf, shape} from 'prop-types'
import {graphql} from 'gatsby'
import React from 'react'

import useIsMobile from 'hooks/useIsMobile'

import {
  aboutUsPropTypes,
  carouselPropTypes,
  insightsPropTypes,
  joinUsPropTypes,
  manifestoPropTypes,
  pageContextPropTypes,
  playbookPropTypes,
  servicesPropTypes,
  worldwidePropTypes,
} from 'helpers/propTypes'

import Box from 'components/UI/Box'
import ContentSection from 'components/UI/ContentSection'
import FeaturedCarousel from 'components/HomeV2/FeaturedCarousel'
import Heading from 'components/UI/Heading'
import MiniPictures from 'components/UI/MiniPictures'
import Section from 'components/UI/Section'
import SEO from 'components/seo'
import Title from 'components/UI/Title'

const RootIndex = ({pageContext, data}) => {
  const countryData = R.path(['contentfulCountry'], data)

  const isMobile = useIsMobile()

  const {
    metaTitle,
    metaDescription,
    // primaryText,
    // contentGrid,
    slider2,
    slider3,
    pageItems,
    pageTitle,
    shortDescription,
    kpisSectionTitle,
    kpisSection,
    companyPurpose,
    callToAction,
    callToActionLink,
  } = R.pathOr(null, ['allContentfulHomepageV2', 'edges', 0, 'node'], data)

  const tooltipHumanData = R.path(
    ['tooltipHumanData', 'tooltipHumanData'],
    companyPurpose,
  )
  const tooltipInspirationalReality = R.path(
    ['tooltipInspirationalReality', 'tooltipInspirationalReality'],
    companyPurpose,
  )
  const tooltipSimplexFuture = R.path(
    ['tooltipSimplexFuture', 'tooltipSimplexFuture'],
    companyPurpose,
  )

  const {name: companyPurposeName, title: companyPurposeTitle} = companyPurpose

  const lastPublishedInitiative = R.pathOr(
    null,
    ['lastPublishedInitiative', 'nodes', 0],
    data,
  )

  const takeRandom = (n, list) =>
    R.pipe(
      R.sort(() => Math.random() - 0.5),
      R.take(n),
    )(list)

  const allPrograms = takeRandom(
    3,
    R.pathOr([], ['allContentfulProgram', 'nodes'], data),
  )

  const renderSlider2 = slider2 || lastPublishedInitiative

  const pageMetaData = {
    metaTitle: R.pathOr('', ['metaTitle'], metaTitle) || metaTitle,
    metaDescription: R.pathOr('', ['metaDescription'], metaDescription),
    siteMetaData: R.pathOr('', ['siteMetaData'], data),
    genericData: R.pathOr('', ['contentfulGenericData'], data),
  }

  return (
    <>
      <SEO
        pageContext={pageContext}
        countryData={countryData}
        pageMetaData={pageMetaData}
      />
      <Section hasVerySmallPadding>
        <FeaturedCarousel
          slider1={allPrograms}
          slider2={renderSlider2}
          slider3={slider3}
          title={pageTitle}
          raw={shortDescription}
          callToAction={callToAction}
          callToActionLink={callToActionLink}
          socialMediaLinks={data.socialMediaLinks.nodes[0].socialMediaLinks}
        />
      </Section>
      <Section
        hasVerySmallPadding={!isMobile}
        hasPaddingTop={!isMobile}
        hasPaddingBottom={!isMobile}
      >
        <Heading isCentered hasMargins>
          <Title
            variant="h2"
            type="largeTitle"
            hat={companyPurposeName}
            isCentered
          >
            {companyPurposeTitle}
          </Title>
        </Heading>
        <MiniPictures
          cards={companyPurpose.contentsSections1}
          directLink="../about-us#company-purpose"
          tooltipHumanData={tooltipHumanData}
          tooltipInspirationalReality={tooltipInspirationalReality}
          tooltipSimplexFuture={tooltipSimplexFuture}
          hasNoTooltip
          hasNoDescription
        />
      </Section>
      {R.map(
        node => (
          <Section
            hasVerySmallPadding={!isMobile}
            hasPaddingTop={!isMobile}
            hasPaddingBottom={!isMobile}
          >
            <ContentSection
              hatTitle={node.hatTitle}
              title={node.title}
              callToAction={node.callToAction}
              variation={node.variation}
              description={node.description}
              image={node.image}
              id={node.id}
              key={node.id}
              typeOfLink={node.typeOfLink}
              link={node.link}
              countryData={countryData}
              hasBorderRadius
            />
          </Section>
        ),
        pageItems,
      )}
      <Section hasVerySmallPadding>
        <Title
          variant="h2"
          type="largeTitle"
          hat={kpisSectionTitle}
          isCentered
        />
        <Box kpis={kpisSection} />
      </Section>
    </>
  )
}

RootIndex.propTypes = {
  data: shape({
    allContentfulpage: shape({
      edges: arrayOf(
        shape({
          node: shape({
            mainCarousel: carouselPropTypes,
            manifesto: manifestoPropTypes,
            services: servicesPropTypes,
            playbook: playbookPropTypes,
            joinUs: joinUsPropTypes,
            aboutUs: aboutUsPropTypes,
            worldwide: worldwidePropTypes,
            insights: insightsPropTypes,
          }),
        }),
      ),
    }),
  }).isRequired,
  pageContext: pageContextPropTypes.isRequired,
}

RootIndex.defaultProps = {}

export default RootIndex

export const pageQuery = graphql`
  query templateHome(
    $entityRegEx: String
    $nodeLocale: String
    $technicalName: String
  ) {
    contentfulCountry(
      node_locale: {eq: $nodeLocale}
      technicalName: {eq: $technicalName}
    ) {
      ...countryDataFields
      id
      name
      carousel {
        title
        description {
          description
        }
        buttonLabel
        buttonLink
        imageOrVideo {
          gatsbyImageData(placeholder: BLURRED)
          file {
            url
            contentType
          }
          description
        }
      }
      ...partnerFields
      services {
        topLevelServices {
          entryId
          subLevelServices {
            entryId
          }
        }
      }
    }
    careerLink: contentfulMenuItem(name: {eq: "Careers-KM"}) {
      link
    }
    siteMetaData: site {
      ...SiteMetadata
    }
    contentfulGenericData {
      genericKeywords {
        content
      }
    }
    allContentfulHomepageV2(
      filter: {node_locale: {eq: $nodeLocale}, slug: {regex: $entityRegEx}}
    ) {
      edges {
        node {
          ...homepageV2Fields
        }
      }
    }
    lastPublishedInitiative: allContentfulInitiative(
      filter: {
        node_locale: {eq: $nodeLocale}
        countriesOfDestination: {
          elemMatch: {technicalName: {eq: $technicalName}}
        }
      }
      sort: {publicationDate: DESC}
      limit: 1
    ) {
      nodes {
        slug
        title
        image {
          gatsbyImageData(placeholder: BLURRED)
          file {
            url
            fileName
            contentType
          }
        }
        __typename
      }
    }
    allContentfulProgram(
      filter: {node_locale: {eq: $nodeLocale}}
      sort: {publicationDate: DESC}
    ) {
      nodes {
        contentful_id
        type {
          name {
            name
          }
        }
        slug
        title
        image {
          gatsbyImageData(placeholder: BLURRED)
          file {
            url
            fileName
            contentType
          }
        }
        __typename
      }
    }
    socialMediaLinks: allContentfulHomepage(
      filter: {node_locale: {eq: $nodeLocale}, slug: {eq: "home"}}
    ) {
      nodes {
        socialMediaLinks {
          id
          name
          link
          icon {
            file {
              url
            }
          }
          iconHover {
            file {
              url
            }
          }
        }
      }
    }
  }
`
