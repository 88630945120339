import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({spacing, breakpoints, fontSizes, palette}) => ({
  heroTextContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    [breakpoints.down('md')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      gap: 50,
    },
  },
  container: {
    borderRadius: '26.7px 6.697px 26.789px 6.697px',
    boxShadow: '2.01px 6.39px 17.41281px 0px rgba(0, 0, 0, 0.12)',
    padding: spacing(5, 12),
    zIndex: 3,
    width: '100%',
    [breakpoints.down('sm')]: {
      padding: spacing(5, 6),
    },
  },
  containerWide: {
    [breakpoints.up('md')]: {
      padding: spacing(10, 12),
    },
  },
  wrapper: {
    display: 'flex ',
    gap: spacing(6),
    justifyContent: 'center',
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: spacing(4, 1),
    },
  },
  keyNumbers: {
    display: 'flex',
    width: '100%',
    flex: '30%',
    flexDirection: 'row',
    alignItems: 'center',
    textAlign: 'left',
    justifyContent: 'center',
    '& *': {
      margin: 0,
    },
    '& h3': {
      fontFamily: 'CamptonMedium',
      fontWeight: 500,
      fontSize: fontSizes.subTitle,
    },
    '& p': {
      fontFamily: 'CamptonLight',
      fontWeight: 300,
      fontSize: fontSizes.description,
    },
    [breakpoints.down('md')]: {
      gap: 20,
    },
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      textAlign: 'center',
    },
  },
  keyNumber: {
    fontSize: 45,
    margin: spacing(0, 3),
  },
  keyDescription: {
    flexDirection: 'column',
  },
  BoxWithCtaContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: spacing(3),
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
      textAlign: 'center',
    },
  },

  boxPrimaryText: {
    flex: 3,
    color: palette.primary.main,
  },
  boxSecondaryText: {
    flex: 6,
    fontFamily: 'CamptonBook',
  },
}))

export default useStyles
