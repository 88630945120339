import * as R from 'ramda'
import {objectOf, string} from 'prop-types'
import React from 'react'

import RoundButton from 'components/UI/RoundButton'
import Vignette from '../Vignette'

const ContentVignette = ({slider, borderRadius}) => {
  const {primaryText} = R.pathOr('', ['primaryText'], slider)
  const {secondaryText} = R.pathOr('', ['secondaryText'], slider)
  const {tertiaryText} = R.pathOr('', ['tertiaryText'], slider)
  const {cta, ctaLink} = R.pathOr('', [], slider)

  return (
    <Vignette borderRadius={borderRadius}>
      <h4 style={{color: '#28c9ff'}}>{primaryText}</h4>
      <p style={{textAlign: 'center'}}>{secondaryText}</p>
      <RoundButton href={`../${ctaLink}`}>{cta}</RoundButton>
    </Vignette>
  )
}

ContentVignette.propTypes = {
  borderRadius: string,
  slider: objectOf,
}
ContentVignette.defaultProps = {
  borderRadius: '',
  slider: {},
}

export default ContentVignette
