import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({breakpoints, spacing}) => ({
  vignetteWrapper: {
    flex: 1,
    width: '100%',
  },
  vignetteLink: {
    color: 'black',
    '&:hover': {
      color: 'black',
    },
    [breakpoints.down('sm')]: {
      display: 'block',
      height: 200,
      '& img': {
        height: 200,
      },
    },
  },
  vignetteImage: {
    height: '100%',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderRadius: 20,
    transition: 'all 0.3s ease-in-out',
  },
  vignetteSubWrapper: {
    height: '100%',
    width: '100%',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    display: 'flex',
  },
  vignetteDiv: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    fontFamily: 'CamptonBook',
    [breakpoints.down('sm')]: {},
  },
}))

export default useStyles
