import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({breakpoints, fontSizes, palette, spacing}) => ({
  container: {
    display: 'flex',
    [breakpoints.up('sm')]: {
      gap: spacing(6),
    },
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    flex: '45%',
    width: '50%',
    justifyContent: 'center',
    [breakpoints.down('sm')]: {
      width: '100%',
      '& div:nth-child(2)': {
        textAlign: 'left',
      },
      textAlign: 'center',
    },
  },
  description: {
    fontFamily: 'CamptonLight',
    color: palette.text.primary,
    marginBottom: spacing(1.25),
  },
  preWrap: {
    whiteSpace: 'pre-wrap',
  },
  largeDescription: {
    fontSize: fontSizes.description,
    [breakpoints.down('sm')]: {
      fontSize: fontSizes.description,
    },
    '& p': {
      fontSize: fontSizes.description,
      [breakpoints.down('sm')]: {
        fontSize: fontSizes.description,
      },
    },
  },
  carouselContainer: {
    flex: '55%',
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    height: 550,
    [breakpoints.down('md')]: {
      width: '100%',
      marginTop: spacing(4),
    },
  },
  carouselImageContainer: {
    position: 'relative',
    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
    width: '100%',
    height: '-webkit-fill-available',
    [breakpoints.up('md')]: {
      flex: 1,
    },
    [breakpoints.down('md')]: {
      height: 300,
    },
  },
  vignettesContainer: {
    display: 'flex',
    gap: '10px',
    width: '100%',
    height: '40%',
    [breakpoints.down('sm')]: {
      height: '100%',
      display: 'block',
      margin: spacing(3, 0),
    },
  },
}))

export default useStyles
