import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({breakpoints, fontSizes, palette, layout}) => ({
  media: {
    height: '100%',
    borderRadius: 20,
  },

  carouselArrowsWrapper: {
    width: '100%',
    position: 'fixed',
    borderRadius: '65px 20px 20px 20px',
    zIndex: 0,
    [breakpoints.down('sm')]: {
      height: 200,
      borderRadius: '35px 20px 20px 20px',
      background:
        'linear-gradient(180deg, rgb(255 255 255) 0%, rgb(225 225 225 / 0%) 0.01%, rgb(241 241 241 / 14%) 51.56%, rgb(255 255 255) 100%)',
    },
    [breakpoints.up('sm')]: {
      height: '100%',
      background:
        'linear-gradient(180deg, rgb(255 255 255) 0%, rgb(225 225 225 / 0%) 0.01%, rgb(241 241 241 / 14%) 51.56%, rgb(255 255 255) 100%)',
    },
  },

  tag: {
    pointerEvents: 'none',
    textTransform: 'none',
    fontSize: fontSizes.small,
    maxHeight: layout.tags.maxHeight,
  },

  serviceTags: {
    color: palette.tags.departments.color,
    backgroundColor: palette.tags.departments.backgroundColor,
  },

  carouselArrowsContainer: {
    gap: 4,
    color: 'black',
    margin: 'auto',
    position: 'fixed',

    bottom: '5%',
    left: '3%',
    display: 'flex',
    flexDirection: 'column',
    '& div': {
      cursor: 'pointer',
      marginRight: '2rem',
    },
    [breakpoints.down('sm')]: {
      position: 'absolute',
    },
  },
}))

export default useStyles
