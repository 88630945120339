/* eslint-disable no-underscore-dangle */
import * as R from 'ramda'
import {objectOf} from 'prop-types'
import Button from '@mui/material/Button'
import classNames from 'classnames'
import React from 'react'

import Media from 'components/UI/Media'
import Title from 'components/UI/Title'
import useIsMobile from 'hooks/useIsMobile'
import useIsSmallMobile from 'hooks/useIsSmallMobile'

import useStyles from './styles'

const ContentSlider2 = ({slider}) => {
  const classes = useStyles()
  const isMobile = useIsMobile()
  const isSmallMobile = useIsSmallMobile()
  const {image, __typename, title} = slider
  const {primaryText} = R.pathOr('', ['primaryText'], slider)

  const handleContentTypeTag = () =>
    slider.__typename === 'ContentfulInitiative'
      ? slider.__typename.replace('Contentful', '#')
      : `#${slider.type?.name?.name}`

  return (
    <>
      <div
        style={{
          height: '100%',
          background: 'linear-gradient(0deg, #ffffff26, transparent)',
          position: 'relative',
        }}
      >
        <Media className={classes.media} data={image.gatsbyImageData} />
        <div
          className={classNames(classes.carouselArrowsWrapper)}
          style={{
            zIndex: (isMobile || isSmallMobile) && 1000,
            position: 'absolute',
            bottom: 0,
          }}
        >
          <div
            className={classNames(classes.carouselArrowsContainer)}
            style={{
              zIndex: (isMobile || isSmallMobile) && 1000,
              position: 'absolute',
              left: '3%',
            }}
          >
            <div>
              <Button
                className={classNames(classes.tag, classes.serviceTags)}
                size="small"
              >
                {handleContentTypeTag(slider)}
              </Button>
            </div>
            <Title hasSmallPaddingBottom variant="h2" type="smallTitle">
              {__typename === 'ContentfulContent' ? primaryText : title}
            </Title>
          </div>
        </div>
      </div>
    </>
  )
}

ContentSlider2.propTypes = {
  slider: objectOf,
}

ContentSlider2.defaultProps = {
  slider: {},
}

export default ContentSlider2
