import React from 'react'

const RightArrow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="18"
    viewBox="0 0 25 19"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.7852 16.4215L14.794 18.3268L24.4024 9.21226L14.794 0.0976562L12.7852 2.00295L19.0103 7.90625L0.701172 7.90625V10.518L19.0106 10.518L12.7852 16.4215Z"
      fill="#303030"
    />
  </svg>
)

export default RightArrow
