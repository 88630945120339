import * as R from 'ramda'
import {arrayOf, bool, shape, string} from 'prop-types'
import classNames from 'classnames'
import React from 'react'

import RoundButton from 'components/UI/RoundButton'

import useStyles from './styles'

const Box = ({isCta, isWide, kpis, box}) => {
  const classes = useStyles()

  const boxData = {
    primaryText: R.pathOr('', ['primaryText', 'primaryText'], box),
    secondaryText: R.pathOr('', ['secondaryText', 'secondaryText'], box),
    cta: R.pathOr('', ['cta'], box),
    ctaLink: R.pathOr('', ['ctaLink'], box),
  }

  const BoxWithCta = () => (
    <>
      {boxData && (
        <div className={classes.BoxWithCtaContainer}>
          <h3 className={classes.boxPrimaryText}>{boxData.primaryText}</h3>
          <div className={classes.boxSecondaryText}>
            {boxData.secondaryText}
          </div>
          <RoundButton href={boxData.ctaLink} style={{flex: '3'}}>
            {boxData.cta}
          </RoundButton>
        </div>
      )}
    </>
  )

  const KpiBox = () => (
    <>
      {kpis &&
        R.map(
          kpi => (
            <div className={classes.keyNumbers}>
              <div className={classes.keyNumber}>{kpi.number}</div>
              <div className={classes.keyDescription}>
                <p style={{fontFamily: 'camptonSemiBold'}}>{kpi.primaryText}</p>
                <p>{kpi.shortText}</p>
              </div>
            </div>
          ),
          kpis,
        )}
    </>
  )

  return (
    <div className={classes.heroTextContainer}>
      <div
        className={classNames(classes.container, {
          [classes.containerWide]: isWide,
        })}
      >
        <div className={classes.wrapper}>
          {isCta ? <BoxWithCta /> : <KpiBox />}
        </div>
      </div>
    </div>
  )
}

Box.propTypes = {
  isCta: bool,
  isWide: bool,
  kpis: arrayOf(
    shape({
      number: string,
      primaryText: string,
      shortText: string,
    }),
  ),
}

Box.defaultProps = {
  isCta: false,
  isWide: false,
  kpis: null,
}

export default Box
