/* eslint-disable no-underscore-dangle */
import * as R from 'ramda'
import {a} from 'react-spring'
import {Link} from 'gatsby'
import Button from '@mui/material/Button'
import Carousel from 'nuka-carousel'
import classNames from 'classnames'
import React from 'react'

import Media from 'components/UI/Media'
import Title from 'components/UI/Title'
import useIsMobile from 'hooks/useIsMobile'
import useIsSmallMobile from 'hooks/useIsSmallMobile'

import useStyles from './styles'

const MobileCarousel = ({index, setIndex, slider, renderSlug}) => {
  const classes = useStyles()
  const isMobile = useIsMobile()
  const isSmallMobile = useIsSmallMobile()
  const mapIndexed = R.addIndex(R.map)

  return (
    <>
      <Carousel
        slideIndex={index}
        afterSlide={slideIndex => setIndex(slideIndex)}
        wrapAround
        withoutControls
      >
        {slider.map((item, i) => (
          <>
            <div
              key={slider[index].contentful_id}
              className={`${classes.carouselItemContainer} carousel-item ${
                i === index ? 'active' : ''
              }`}
            >
              <Media
                className={classes.carouselImageMobile}
                data={slider[index].image.gatsbyImageData}
              />
            </div>
            <Link
              to={renderSlug(item)}
              className={` ${i === index ? classes.linkContainer : ''}`}
              style={{
                zIndex: (isMobile || isSmallMobile) && 2001,
              }}
            />

            <div
              className={classNames(classes.carouselArrowsWrapper, {
                [`carousel-item ${i === index ? 'active' : ''}`]:
                  isMobile || isSmallMobile,
              })}
              style={{
                zIndex: (isMobile || isSmallMobile) && 1000,
              }}
            >
              <div
                className={classNames(classes.carouselArrowsContainerMobile, {
                  [`carousel-item ${i === index ? 'active' : ''}`]:
                    isMobile || isSmallMobile,
                })}
                style={{
                  position: (isMobile || isSmallMobile) && 'absolute',
                  zIndex: (isMobile || isSmallMobile) && 1000,
                }}
              >
                <div>
                  <Button
                    className={classNames(classes.tag, classes.serviceTags, {})}
                    size="small"
                  >
                    {slider[index].__typename === 'ContentfulInitiative'
                      ? slider[index].__typename.replace('Contentful', '#')
                      : slider[index].type.name.name}
                  </Button>
                </div>
                <Title hasSmallPaddingBottom variant="h2" type="smallTitle">
                  {slider[index].__typename === 'ContentfulContent'
                    ? slider[index]?.primaryText?.primaryText
                    : slider[index].title}
                </Title>
              </div>
            </div>
          </>
        ))}
      </Carousel>
      <div className={classes.dotsContainer}>
        <div className={classes.dotsContainer}>
          {mapIndexed(
            (node, indx) => (
              <a.div
                className={classNames(
                  classes.dots,
                  index === indx && classes.activeDots,
                )}
              />
            ),
            slider,
          )}
        </div>

        {/* <Dots index={index} count={R.length(slider)} className={classes.dots} /> */}
      </div>
    </>
  )
}

export default MobileCarousel
