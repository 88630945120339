import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(
  ({breakpoints, fontSizes, palette, layout, spacing}) => ({
    carouselItemContainer: {
      height: '100%',
      background: 'linear-gradient(0deg, #ffffff26, transparent)',
    },

    dotsContainer: {
      justifyContent: 'center',
      display: 'flex',
      margin: '.5rem',
    },

    carouselImageMobile: {
      borderRadius: '65px 20px 20px 20px',
      background: 'linear-gradient(0deg, white,#ffffff7a, transparent)',
      '& div': {
        height: 300,
      },
    },

    carouselArrowsWrapper: {
      width: '100%',
      position: 'absolute',
      borderRadius: '65px 20px 20px 20px',
      zIndex: 0,
      background:
        'linear-gradient(180deg, rgba(255, 255, 255, 0.70) 0%, rgba(225, 225, 225, 0.00) 0.01%, rgba(241, 241, 241, 0.00) 51.56%, rgb(255 255 255 / 75%) 100%)',
      [breakpoints.down('sm')]: {
        height: 300,
        borderRadius: '35px 20px 20px 20px',
      },
      [breakpoints.up('sm')]: {
        height: '100%',
      },
    },

    carouselArrowsContainerMobile: {
      top: 230,
      left: 15,
      height: 300,
    },

    tag: {
      pointerEvents: 'none',
      textTransform: 'none',
      fontSize: fontSizes.small,
      maxHeight: layout.tags.maxHeight,
    },

    serviceTags: {
      color: palette.tags.departments.color,
      backgroundColor: palette.tags.departments.backgroundColor,
    },

    linkContainer: {
      background: 'transparent',
      position: 'absolute',
      zIndex: 1,
      display: 'block',
      height: '80%',
      width: '100%',
    },

    dots: {
      borderRadius: '50%',
      width: spacing(1),
      height: spacing(1),
      margin: spacing(0, 1.25, 0, 0),
      backgroundColor: palette.background.gray,
      transitionProperty: 'transform',
      transitionDuration: '0.5s',
    },
    activeDots: {
      backgroundColor: palette.primary.main,
      transform: 'scale(1.3)',
    },
  }),
)

export default useStyles
