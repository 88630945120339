import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(
  ({breakpoints, fontSizes, palette, layout, spacing}) => ({
    carouselItemContainer: {
      height: '100%',
      background: 'linear-gradient(0deg, #ffffff26, transparent)',
    },

    carouselImage: {
      width: '100%',
      height: '100%',
      borderRadius: '65px 20px 20px 20px',
      background: 'linear-gradient(0deg, white,#ffffff7a, transparent)',
      '& div': {
        height: 300,
      },
    },

    carouselArrowsWrapper: {
      width: '100%',
      position: 'absolute',
      borderRadius: '65px 20px 20px 20px',
      zIndex: 0,
      background:
        'linear-gradient(180deg, rgba(255, 255, 255, 0.70) 0%, rgba(225, 225, 225, 0.00) 0.01%, rgba(241, 241, 241, 0.00) 51.56%, rgb(255 255 255 / 75%) 100%)',
      [breakpoints.down('sm')]: {
        height: 300,
        borderRadius: '35px 20px 20px 20px',
      },
      [breakpoints.up('sm')]: {
        height: '100%',
      },
    },

    carouselArrowIconsContainer: {
      display: 'flex',
      '& button': {
        userSelect: 'none',
        border: 'none',
        background: 'transparent',
        padding: 0,
        margin: 0,
        marginRight: '2rem',
      },
    },

    carouselArrowsContainer: {
      width: '90%',
      gap: 4,
      color: 'black',
      margin: 'auto',
      position: 'absolute',
      bottom: '5%',
      left: '3%',
      display: 'flex',
      flexDirection: 'column',
      '& div': {
        cursor: 'pointer',
        marginRight: '2rem',
      },
      [breakpoints.down('sm')]: {
        position: 'absolute',
      },
    },

    tag: {
      pointerEvents: 'none',
      textTransform: 'none',
      fontSize: fontSizes.small,
      maxHeight: layout.tags.maxHeight,
    },

    serviceTags: {
      color: palette.tags.departments.color,
      backgroundColor: palette.tags.departments.backgroundColor,
    },

    linkContainer: {
      background: 'transparent',
      position: 'absolute',
      zIndex: 1,
      display: 'block',
      height: '80%',
      width: '100%',
    },
  }),
)

export default useStyles
