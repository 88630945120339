import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(() => ({
  kpi2Container: {
    display: 'flex',
    gap: 10,
    color: '#FF9810',
    marginTop: 10,
    alignItems: 'center',
    '& > div:first-child': {
      fontFamily: 'CamptonSemiBold',
      fontSize: 60,
    },
    '& > div:last-child': {
      '& > div:first-child': {
        fontFamily: 'CamptonSemiBold',
      },
      display: 'flex',
      gap: 3,
      flexDirection: 'column',
    },
  },
}))

export default useStyles
