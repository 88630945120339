import * as R from 'ramda'
import {objectOf} from 'prop-types'
import React from 'react'

import RoundButton from 'components/UI/RoundButton'

import useStyles from './styles'

const ContentSlider3 = ({slider}) => {
  const classes = useStyles()

  const {cta, ctaLink} = slider
  const {primaryText} = R.pathOr('', ['primaryText'], slider)
  const {secondaryText} = R.pathOr('', ['secondaryText'], slider)
  const {tertiaryText} = R.pathOr('', ['tertiaryText'], slider)

  return (
    <>
      <RoundButton href={`../${ctaLink}`} color="secondary">
        {cta}
      </RoundButton>
      <div className={classes.kpi2Container}>
        <div>{primaryText}</div>
        <div>
          <div>{secondaryText}</div>
          <div>{tertiaryText}</div>
        </div>
      </div>
    </>
  )
}

ContentSlider3.propTypes = {
  slider: objectOf,
}

ContentSlider3.defaultProps = {
  slider: {},
}

export default ContentSlider3
