import React from 'react'

const LeftArrow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="18"
    viewBox="0 0 24 19"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6172 2.00428L9.60838 0.0989844L-0.000104107 9.21352L9.60837 18.3281L11.6172 16.4228L5.39192 10.5195L23.7012 10.5195L23.7012 7.90773L5.39184 7.90772L11.6172 2.00428Z"
      fill="#303030"
    />
  </svg>
)

export default LeftArrow
