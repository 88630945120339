/* eslint-disable react/no-danger */
/* eslint-disable no-underscore-dangle */

import {arrayOf, objectOf, string} from 'prop-types'
import classNames from 'classnames'
import React from 'react'

import RawText from 'components/UI/RawText'
import RoundButton from 'components/UI/RoundButton'
import Social from 'components/UI/Social'
import useIsMobile from 'hooks/useIsMobile'

import ArticleVignette from './ArticleVignette'
import ContentSlider1 from './ContentSlider1'
import ContentSlider2 from './ContentSlider2'
import ContentSlider3 from './ContentSlider3'
import ContentVignette from './ContentVignette'
import MainCarouselDecorations from '../../Home/MainCarousel/MainCarouselDecorations'
import useStyles from './styles'
import Vignette from './Vignette'

const FeaturedCarousel = ({
  slider1,
  slider2,
  slider3,
  title,
  raw,
  callToAction,
  callToActionLink,
  socialMediaLinks,
}) => {
  const classes = useStyles()
  const isMobile = useIsMobile()

  return (
    <div className={classes.container}>
      <MainCarouselDecorations />

      <div className={classes.content}>
        <div
          style={{fontSize: 35}}
          dangerouslySetInnerHTML={{__html: `<h1>${title}</h1>`}}
        />
        <div
          className={classNames(
            classes.description,
            classes.preWrap,
            classes.largeDescription,
          )}
        >
          <RawText text={raw} />
        </div>
        <div style={{margin: '10px 0px'}}>
          <RoundButton href={callToActionLink}>{callToAction}</RoundButton>
        </div>
        <Social socialMediaLinks={socialMediaLinks} hasMarginTop />
      </div>

      <div className={classes.carouselContainer}>
        <div className={classes.carouselImageContainer}>
          <ContentSlider1 slider={slider1} />
        </div>
        <div className={classes.vignettesContainer}>
          {slider2.__typename === 'ContentfulContent' && (
            <ContentVignette slider={slider2} borderRadius="20px" />
          )}
          {slider2.__typename === 'ContentfulInitiative' && (
            <ArticleVignette
              data={slider2}
              borderRadius="20px"
              justifyContent="end"
            >
              <ContentSlider2 slider={slider2} />
            </ArticleVignette>
          )}
          <Vignette
            borderRadius={
              isMobile ? '20px 20px 35px 20px' : '20px 20px 65px 20px'
            }
          >
            <ContentSlider3 slider={slider3} />
          </Vignette>
        </div>
      </div>
    </div>
  )
}

FeaturedCarousel.propTypes = {
  callToAction: string,
  callToActionLink: string,
  raw: objectOf,
  slider1: arrayOf,
  slider2: objectOf,
  slider3: objectOf,
  socialMediaLinks: arrayOf,
  title: string,
}

FeaturedCarousel.defaultProps = {
  callToAction: '',
  callToActionLink: '',
  raw: {},
  slider1: [],
  slider2: {},
  slider3: {},
  socialMediaLinks: [],
  title: '',
}

export default FeaturedCarousel
