import LinearProgress from '@mui/material/LinearProgress'
import React, {useEffect, useState} from 'react'

const ProgressComponent = () => {
  const [progress, setProgress] = useState(0)

  const duration = 6000
  const intervalTime = duration / 100

  const updateProgress = () => {
    setProgress(prevProgress => (prevProgress < 100 ? prevProgress + 1 : 100))

    if (progress < 100) {
      setTimeout(updateProgress, intervalTime)
    }
  }

  useEffect(() => {
    if (progress === 0) {
      updateProgress()
    }
  }, [progress])

  return (
    <div
      style={{
        position: 'absolute',
        width: '94%',
        bottom: -2,
        zIndex: 9,
        borderRadius: 100,
        margin: 'auto',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <LinearProgress
        style={{
          height: 2,
          borderRadius: 50,
          opacity: 0.7,
        }}
        variant="determinate"
        value={progress}
      />
    </div>
  )
}

export default ProgressComponent
